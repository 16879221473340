@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto:100,400,500,500i,900&display=swap&subset=cyrillic');

@import "common/breakpoints";
@import "common/variables";

@import "common/footer";
@import "common/header";

html,
body {
    color: white;
    display: block;
    height: 90%;
    width: 100%;
    background-color: $color-bg-gray;
}

p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-font;
    padding: 20px 0;
}

h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 60px;
    line-height: 65px;
    color: white;
    text-align: center;
}

h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: $color-dark-blue;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 10px;

    @include breakpoint($sm-max) {
        font-size: 30px;
        line-height: 1.2;
    }
}

h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    color: $color-dark-blue;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
}

.bg_l-blue {
    background-color: $color-ligt-blue-5;
    padding: 20px 0;

    &.triangle {
        background: url(../images/bg-white-triangle.svg) no-repeat;
        background-position: center -2px;
        background-size: 50%;
        background-color: $color-ligt-blue-5;
        padding-top: 0;
    }
}

.bg_blue {
    background-color: $color-blue-5;
    padding: 20px 0;

    &.triangle {
        background: url(../images/bg-blue-triangle.svg) no-repeat;
        background-position: center -2px;
        background-size: 50%;
        background-color: $color-blue-5;
        padding-top: 40px;
    }
}

.head-banner {
    background: url(../images/bg-main-banner.jpg) no-repeat;
    background-color: white;
    background-position: center top;
    background-attachment: fixed;
    display: block;

    @include breakpoint($lg-max) {
        background-position: -380px top;
    }

    h1 {
        padding-top: 315px;
        padding-bottom: 10px;

        @include breakpoint($sm-max) {
            padding-top: 200px;
            font-size: 38px;
            line-height: 1;
        }
    }

    p {
        color: $color-yellow;
        font-size: 25px;
        line-height: 1;
        text-align: center;
        font-weight: 500;
        padding-bottom: 250px;

        @include breakpoint($sm-max) {
            padding-bottom: 200px;
            font-size: 20px;
        }
    }
}

.form-control {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border: 1px solid $color-border;
    margin: 10px 0;
    font-size: 16px;
    padding: 25px;

    &:focus {
        border-color: $color-yellow;
        -webkit-box-shadow: 0 0 0 1px $color-yellow;
        box-shadow: 0 0 0 1px $color-yellow;
    }
}

.btn-warning {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border: 1px solid $color-yellow;
    background-color: $color-yellow;
    margin: 10px 0;
    font-size: 16px;
    padding: 13px;
    color: $color-font;

    &:hover {
        background-color: $color-ligt-blue;
        border: 1px solid $color-ligt-blue;
    }

    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
        border-color: $color-yellow;
        -webkit-box-shadow: 0 0 0 1px $color-yellow;
        box-shadow: 0 0 0 1px $color-yellow;
        background-color: $color-ligt-blue;
    }

}

.text-muted {
    text-align: right;
    font-size: 12px;
    line-height: 1;
    color: $color-text-muted;

    a {
        color: $color-text-muted;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    @include breakpoint($lg-max) {
        text-align: center;
    }
}

.fos {
    margin-bottom: 60px;

    .fos-bg {
        background: url(../images/fos-bg-people.png) no-repeat center left;
        border: 1px solid $color-border;
        position: relative;
        margin-top: -150px;
        background-color: white;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        background-size: 37%;
        padding-bottom: 40px;

        @include breakpoint($lg-max) {
            background-image: none;
        }

        @include breakpoint($sm-max) {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    .fos-title {
        text-transform: uppercase;
        color: $color-dark-blue;
        font-size: 32px;
        line-height: 1;
        font-weight: 500;
        text-align: center;
        padding-top: 50px;

        @include breakpoint($sm-max) {
            font-size: 24px;
        }
    }

    p {
        font-size: 24px;
        line-height: 1.5;
        text-align: center;

        b {
            font-weight: 500;
        }

        @include breakpoint($sm-max) {
            font-size: 18px;
            padding-top: 0;
        }
    }
}

.value {
    p {
        color: $color-font;
        text-align: center;
        font-size: 16px;
        line-height: 1;
    }

    .unlinear {
        display: block;
    }

    .linear {
        display: none;
    }

    @include breakpoint($lg-max) {
        .unlinear {
            display: none;
        }

        .linear {
            display: block;
        }
    }

    .title {
        text-align: left;
        font-size: 24px;
        line-height: 1;
        text-transform: uppercase;
        color: $color-dark-blue;
        font-weight: 900;

        @include breakpoint($lg-max) {
            text-align: center;
        }
    }

    .txt-uppercase {
        text-transform: uppercase;
    }
}

.fos_hard-case {
    background: url(../images/bg-fos_difficult-case.jpg) no-repeat;
    background-color: $color-dark-blue;
    background-position: center top;
    background-attachment: fixed;
    display: block;
    padding-bottom: 60px;

    h2,
    h3,
    p {
        color: white;
        text-align: center;
    }


    h3 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 20px;
    }

    .btn-warning {
        padding-left: 40px;
        padding-right: 40px;

        @include breakpoint($lg-max) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .text-muted {
        color: white !important;
        text-align: center;

        a {
            color: white !important;
        }
    }
}

.contacts {
    padding-bottom: 60px;

    h2 {
        text-align: left;
    }

    p {
        padding: 0;
        margin: 0;
        line-height: 1.3;
    }

    .contacts-txt {
        margin-bottom: 30px;
    }

    @include breakpoint($lg-max) {

        h2,
        .contacts-txt {
            text-align: center;
        }
    }

    .social-net {
        margin-bottom: 30px;

        a {
            padding: 10px 0;
            width: 100%;
            display: inline-block;

            p {
                color: $color-font;
                font-size: 18px;
                line-height: 60px;
                font-weight: 500;

                @include breakpoint($sm-max) {
                    display: none;
                }
            }

            .bg-round {
                width: 60px;
                height: 60px;
                padding: 15px;
                border-radius: 30px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                display: inline-block;
                float: left;
                margin-right: 10px;

                &.bg-tel {
                    background-color: $color-dark-blue;
                }

                &.bg-insta {
                    background-color: $color-instagram;
                }

                &.bg-skype {
                    background-color: $color-skype;
                }

                &.bg-wa {
                    background-color: $color-wa;
                }

                &.bg-viber {
                    background-color: $color-viber;
                }

                &.bg-mail {
                    background-color: $color-mail;
                }

                @include breakpoint($sm-max) {
                    float: none;
                    margin: 0 auto;
                    width: 100%;
                    height: 60px;
                    padding: 15px;
                    border-radius: 30px;
                }
            }

            &:hover {
                text-decoration: none;

                .bg-round {
                    background-color: $color-yellow;
                }

                p {
                    color: $white;
                }
            }
        }
    }

    .manager {
        p {
            text-align: left;

            i {
                font-weight: 500;
                font-style: italic;
            }

            .mansger-name {
                font-weight: 500;
                color: $color-dark-blue;
                font-size: 24px;
            }
        }

        a {
            color: $color-font;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .btn-warning {

            &:hover {
                background-color: $color-dark-blue;
                color: white;
            }
        }
    }
}

.mb-2 {
    margin-bottom: 20px;
}

.reviews {
    min-height: 390px;
    padding: 30px 0;

    .carousel-indicators {
        position: relative;
        top: 0;
        bottom: auto;
    }

    p {
        padding: 10px 0;
        margin: 0;

        span {
            font-size: 24px;
            color: $color-dark-blue;
            padding-top: 30px;
        }

        @include breakpoint($sm-max) {
            text-align: center;
        }
    }

    .carousel-indicators li {
        width: 20px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        background-color: $color-dark-blue;

        &:hover {
            background-color: $color-yellow;
        }

    }
}

.bg-airplane {
    background: #fff url(../images/bg_air.svg) top left;
    background-size: cover;
    margin-bottom: 40px;

    p {
        margin: 0;
    }

    .title {
        text-align: left;
        font-weight: 900;
        font-size: 26px;
        color: $color-dark-blue;
    }

    .text-muted {
        text-align: left;

        a {
            color: $color-text-muted !important;
        }
    }

    .btngroup {
        margin-bottom: 30px;

        .tab1 {
            border-top-left-radius: 30px;
            -webkit-border-top-left-radius: 30px;
            -moz-border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            -webkit-border-bottom-left-radius: 30px;
            -moz-border-bottom-left-radius: 30px;

            border-top-right-radius: 0;
            -webkit-border-top-right-radius: 0;
            -moz-border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            -webkit-border-bottom-right-radius: 0;
            -moz-border-bottom-right-radius: 0;

            margin-right: -1px;

            @include breakpoint($md-max) {
                margin-bottom: 5px;
            }
        }

        .tab2 {
            border-top-left-radius: 0;
            -webkit-border-top-left-radius: 0;
            -moz-border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            -webkit-border-bottom-left-radius: 0;
            -moz-border-bottom-left-radius: 0;

            border-top-right-radius: 0;
            -webkit-border-top-right-radius: 0;
            -moz-border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            -webkit-border-bottom-right-radius: 0;
            -moz-border-bottom-right-radius: 0;

            margin-right: -1px;

            @include breakpoint($md-max) {
                border-top-right-radius: 30px;
                -webkit-border-top-right-radius: 30px;
                -moz-border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                -webkit-border-bottom-right-radius: 30px;
                -moz-border-bottom-right-radius: 30px;

                margin-bottom: 5px;
            }
        }

        .tab3 {
            border-top-left-radius: 0;
            -webkit-border-top-left-radius: 0;
            -moz-border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            -webkit-border-bottom-left-radius: 0;
            -moz-border-bottom-left-radius: 0;

            border-top-right-radius: 0;
            -webkit-border-top-right-radius: 0;
            -moz-border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            -webkit-border-bottom-right-radius: 0;
            -moz-border-bottom-right-radius: 0;

            margin-right: -1px;

            @include breakpoint($md-max) {
                border-top-left-radius: 30px;
                -webkit-border-top-left-radius: 30px;
                -moz-border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
                -webkit-border-bottom-left-radius: 30px;
                -moz-border-bottom-left-radius: 30px;

                margin-top: 5px;
            }
        }

        .tab4 {
            border-top-left-radius: 0;
            -webkit-border-top-left-radius: 0;
            -moz-border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            -webkit-border-bottom-left-radius: 0;
            -moz-border-bottom-left-radius: 0;

            border-top-right-radius: 30px;
            -webkit-border-top-right-radius: 30px;
            -moz-border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            -webkit-border-bottom-right-radius: 30px;
            -moz-border-bottom-right-radius: 30px;


            @include breakpoint($md-max) {
                margin-top: 5px;
            }
        }

        .btn-light {
            background-color: $color-bg-gray;
            border: 1px solid $color-border;
            height: 60px;

            &:hover {
                background-color: $color-ligt-blue;
            }

            &:focus {
                border-color: $color-yellow;
                box-shadow: none;
            }
        }
    }

    a {
        color: $color-font;
        text-align: center !important;
        margin-top: 7px;
        font-weight: 500;

        &:hover {
            color: $color-yellow;
            text-decoration: none;
        }

        &:active {
            color: $color-dark-blue;
        }
    }

    .card-price {
        background-color: white;
        border: 1px solid $color-border;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        display: block;
        margin-bottom: 20px;

        h3 {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        p {
            text-align: center !important;

            span {
                font-size: 48px;
                line-height: 1;
                font-weight: 500;
            }
        }
    }

}

ul.check-list {
    padding: 0 10px;

    li {
        color: $color-font;
        text-align: left;
        margin-bottom: 5px;
        background: url(../images/icons/icon_checked.svg) top left no-repeat;
        background-size: 25px;
        list-style-type: none;
        padding-left: 35px;

        a {
            color: $color-dark-blue !important;
            text-decoration: underline;
            font-weight: 500;
        }
    }
}

.modal-content {
    background-color: white;
    border: 1px solid $color-border;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    display: block;
    padding: 20px auto;

    h3 {
        font-size: 40px;
        font-weight: 900;
    }

    p {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    p,
    .text-muted {
        text-align: center !important;
        max-width: 80%;
        margin: 0 auto;

    }
}

.page {

    height: 110%;
    background-size: cover;
    padding-top: 10%;

    &.bg-404 {
        background: url(../images/bg_404.jpg) center center no-repeat;

        h1 {
            font-weight: 100;
            font-size: 300px;

            @include breakpoint($md-max) {
                font-size: 150px;
            }
        }
    }

    &.bg-thx {
        background: url(../images/bg_thx.jpg) center center no-repeat;
        padding-top: 20%;

        h1 {
            @include breakpoint($md-max) {
                font-size: 40px;
            }
        }
    }

    @include breakpoint($lg-max) {
        padding-top: 30%;
    }

    @include breakpoint($md-max) {
        padding-top: 40%;
    }

    h1 {
        color: white;
        line-height: 1;

    }

    p {
        color: white;
        text-align: center;
    }

    .footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.multi-collapse {
    padding-bottom: 15px;
}

.privacypage {
    background: $color-gray;
    color: black;

    // Подложка для шапки
    &:before {
        content: '';
        display: block;
        height: 80px;
        background-color: $color-dark-blue;
        margin-bottom: 50px;
        @include breakpoint($sm-max) {
            height: 60px;
        }
    }
    .btn {
        max-width: 300px;
        margin: 50px auto;
    }
    h1 {
        font-size: 40px;
        line-height: 40px;
        color: black;
        @include breakpoint($sm-max) {
            font-size: 25px;
            line-height: 28px;
        }
    }
}

.justify-content-center{
    margin: 0;
}
