$xs: (min: 375px);
$sm: (min: 576px);
$md: (min: 768px);
$lg: (min: 992px);
$xl: (min: 1200px);
$xxl: (min: 1441px);
$xxxl: (min: 1921px);
$xs-max: (max: map-get($xs, min) - 1);
$sm-max: (max: map-get($sm, min) - 1);
$md-max: (max: map-get($md, min) - 1);
$lg-max: (max: map-get($lg, min) - 1);
$xl-max: (max: map-get($xl, min) - 1);
$xxl-max: (max: map-get($xxl, min) - 1);
$sm-only: (min: map-get($sm, min), max: map-get($md, min) - 1);
$md-only: (min: map-get($md, min), max: map-get($lg, min) - 1);
$lg-only: (min: map-get($lg, min), max: map-get($xl, min) - 1);
$sm-md: (min: map-get($sm, min), max: map-get($lg, min) - 1);

@mixin breakpoint($map) {
    $query: "";

    @if map-has-key($map, min) {
        $query: append($query, "(min-width: #{map-get($map, min)})");
    }

    @if map-has-key($map, min) and map-has-key($map, max) {
        $query: append($query, "and");
    }

    @if map-has-key($map, max) {
        $query: append($query, "(max-width: #{map-get($map, max)})");
    }

    @media screen and #{$query} {
        @content;
    }
}