.footer {
    background-color: $color-dark-blue;
    padding-top: 10px;
    padding-bottom: 10px;

    p{
        margin: 0;
        padding: 0;
        line-height: 40px;
        color: white;
        font-size: 14px;
        text-align: left!important;
    }
    .signature {
        background: url(../images/signature_white.svg) center right no-repeat;
        background-size: 114px;
        width: 114px;
        float: right;
        height: 40px;
        display: block;
    }
}