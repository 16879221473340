.navbar {
    background-color: transparent;
    transition: all 0.3s ease-in-out;

    >.container {
        position: relative;

        .navbar-toggler {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 15px;

            @include breakpoint($sm-max) {
                top: 5px;
            }
        }
    }

    &.fixed-white {
        background-color: #fff !important;
        border-bottom: 1px solid $color-border;

        a {
            &.navbar-brand {
                background: url('../images/logo_visa-global_blue.svg') no-repeat left center !important;
            }

            &.nav-link,
            &.nav-phone {
                color: $color-dark-blue !important;
                &:hover{
                    color: $color-yellow!important;
                }
            }

            &.nav-phone {
                background: url('../images/icons/icon_tel_b.svg') no-repeat left center !important;

                @include breakpoint($md-max) {
                    background-image: none!important;
                }

                @include breakpoint($sm-max) {
                    background: none !important;
                }

                &:hover {
                    small {
                        color: $color-dark-blue;
                    }
                }
            }
        }
    }
}

.navbar-collapse{
    @include breakpoint($sm-max) {
        background-color: white;
        border-radius: 30px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;

        a.nav-link{
            color: $color-dark-blue!important;

            &:hover{
                color: $color-yellow!important;
            }
        }
    }
}

.navbar-light .navbar-toggler {
    border: none;
}

ul.nav {
    max-width: 375px;
    margin: 0 auto;
    text-align: center;
}

a.nav-link {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: white !important;
    text-transform: uppercase;
    display: inline-block;

    &:hover {
        color: $color-yellow!important;
    }

}

.nav-phone {
    font-family: 'Roboto', sans-serif;
    color: white;
    text-align: right;
    line-height: 1;
    display: block;
    background: url('../images/icons/icon_tel_w.svg') no-repeat left center;
    max-width: 230px;

    @include breakpoint($lg-max) {
        position: absolute;
        right: 0px;
        top: 10px;
    }

    span {
        font-size: 24px;
        font-weight: 500;
        display: inline-block;
        width: 100%;
    }

    small {
        font-size: 14px;
        display: inline-block;
    }

    &:hover {
        text-decoration: none !important;

        span {
            color: $color-yellow;
        }

        small {
            color: white;
        }
    }

    @include breakpoint($md-max) {
        background-image: none;
    }

    @include breakpoint($sm-max) {
        background-image: none;

        span {
            font-size: 14px;
        }

        small {
            display: none;
        }
    }
}

a.navbar-brand {
    background: url('../images/logo_visa-global.svg') no-repeat left center;
    display: block;
    background-size: contain;
    width: 150px;
    height: 60px;

    @include breakpoint($sm-max) {
        width: 100px;
        height: 40px;
    }
}

button.navbar-toggler:focus {
    outline: none !important;
}

.navbar-toggler-icon {
    &:focus {
        box-shadow: none;
        outline: none;
    }
}
